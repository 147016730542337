.header{
    margin-top: 60px;

}
.headerTitles{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family:'Caveat', cursive;
    color: whitesmoke;
    
}

.headerTitleSM{
    position: absolute;
    top: 20%;
    font-size: 100px;

}

.headerImg{
    width: 100%;
    height: 750px;
    margin-top: 80px;
    object-fit: cover;

}