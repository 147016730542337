.nav{
    width: 100%;
    height: 50px;
    background-color: whitesmoke;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    font-family: 'Indie Flower',cursive;
}

.topLeft, .topRight{
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topImg{
    width: 90px;
    height: 90px;
    border-radius: 50%;
    object-fit: cover;
}

.topCenter{
    flex: 6;
}

.topIcon{
    font-size: 40px;
    margin-right: 10px;
    color: #444;
    cursor: pointer;
}

.topList{
    display: flex;
    justify-content: center;
    margin: 0;
    list-style: none;

}

.topListItem{
    margin-right: 20px;
    font-size: 18px;
    font-weight: 300;
    cursor: pointer;

}

.topSearchIcon{
    font-size: 18px;
    color: #446;
    cursor: pointer;
    padding-left: 20px;
}